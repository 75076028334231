<template>

    <div class="py-4 flex group/item">
        <div>
            <span class="font-semibold">{{ notification.event_id }}</span>
            <div>
                {{ notification.id }}
            </div>
        </div>
        <div class="flex flex-col ml-auto justify-end">
            <span class="text-sm">{{ createdAt }}</span>
            <Button icon="pi pi-ellipsis-h invisible group-hover/item:visible ml-auto" aria-label="Menu" text rounded/>
        </div>
    </div>

</template>

<script setup>

import { formatTimeAgo, useTimeAgo } from '@vueuse/core'

const props = defineProps({
    notification: {
        type: Object,
        default: null
    }
})

const createdAt = computed(() => {
    return formatTimeAgo(new Date(props.notification.created_at))
})


</script>